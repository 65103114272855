html {
  height: 100%; }

body {
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* flex: 1 0 auto; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

#root {
  /* width: 100%; */
  /* background: none; */
  /* min-height: 100%; */
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;*/ }

